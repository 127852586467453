import React, { useState } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Logo from "./Logo";
import { useOngoingList } from "../projects/OngoingList";
import useCompletedList from "../projects/useCompletedList";
import Carousel from "nuka-carousel";
import { LeftBtn, RightBtn } from "./MyCarousel";
import { completeddata } from "../projects/commercial/CompletedData";
import PromenadeOngoingData from "../projects/commercial/Promenade/PromenadeOngoingData";

const ChevDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000"
    strokeWidth={1}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-menu"
  >
    <line x1={3} y1={12} x2={21} y2={12} />
    <line x1={3} y1={6} x2={21} y2={6} />
    <line x1={3} y1={18} x2={21} y2={18} />
  </svg>
);

const HeaderLink = ({ title = "", to, isMobile = false }) => {
  return (
    <Link className={isMobile ? "mobile-nav-link" : "header-link"} to={to}>
      {title}
    </Link>
  );
};

const links = [
  // {
  //   to: "/commercial/",
  //   title: "Commercial"
  // },
  {
    to: "/about/",
    title: "About",
  },
  {
    to: "/blog/",
    title: "Blog",
  },

  {
    to: "/media/",
    title: "Media",
  },
  {
    to: "/gallery/",
    title: "Gallery",
  },
  {
    to: "/project-status/",
    title: "Project Status",
  },
  {
    to: "/contact/",
    title: "Contact",
  },
];

const ProjectCol = ({ slug = "", banner, title = "" }) => {
  return (
    <Link className="resident-nav-link" to={`/${slug}/`}>
      {banner && (
        // <Img
        //   className="resident-nav-img bg-light"
        //   fluid={banner.childImageSharp.fluid}
        // />
        <img
          className="resident-nav-img bg-light"
          src={banner.childImageSharp.fluid.src}
        />
      )}
      <div className="resident-nav-link-title border border-top-0">{title}</div>
    </Link>
  );
};

const CustomProjectCol = ({ slug = "", banner, title = "" }) => {
  return (
    <Link className="resident-nav-link" to={`/${slug}/`}>
      {banner && (
        <img
          className="resident-nav-img bg-light"
          src={banner.childImageSharp.fluid.src}
        />
      )}
      <div className="resident-nav-link-title border border-top-0">{title}</div>
    </Link>
  );
};

const ProjectCarousel = ({ title = "", to, list = [] }) => {
  const renderList = list.map(({ node }) => (
    <ProjectCol {...node} key={node.id} />
  ));

  return (
    <div className="row relative">
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h5 className="m-0">{title}</h5>
          <Link className="link-color view-all-txt" to={to}>
            View All
          </Link>
        </div>
        <Carousel
          className="w-100"
          style={{ minHeight: "155px" }}
          slidesToShow={4}
          renderBottomCenterControls={null}
          renderCenterLeftControls={LeftBtn}
          renderCenterRightControls={RightBtn}
          cellSpacing={15}
          heightMode="first"
          initialSlideHeight={155}
        >
          {renderList}
        </Carousel>
      </div>
    </div>
  );
};

const CustomProjectCarousel = ({ title = "", to, list = [] }) => {
  const renderList = list.map((data) => (
    <CustomProjectCol {...data} key={data.id} />
  ));

  return (
    <div className="row relative">
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h5 className="m-0">{title}</h5>
          <Link className="link-color view-all-txt" to={to}>
            View All
          </Link>
        </div>
        <Carousel
          className="w-100"
          style={{ minHeight: "155px" }}
          slidesToShow={4}
          renderBottomCenterControls={null}
          renderCenterLeftControls={LeftBtn}
          renderCenterRightControls={RightBtn}
          cellSpacing={15}
          heightMode="first"
          initialSlideHeight={155}
        >
          {renderList}
        </Carousel>
      </div>
    </div>
  );
};

function Header() {
  const [show, set] = useState(false);
  const ongoing = useOngoingList();
  const completed = useCompletedList();
  const promenadeOngoingData = PromenadeOngoingData();

  return (
    <>
      <div>
        <header className="header" id="header">
          <nav className="d-flex justify-content-between align-items-stretch header-nav container-fluid header-width">
            <Link
              className="d-flex justify-content-between align-items-center"
              to="/"
            >
              <Logo width="240" />
            </Link>
            <div className="header-links">
              <div className="header-link resident-link">
                Residential
                <span className="ml-1">
                  <ChevDown />
                </span>
                <nav className="resident-nav" id="residentNav">
                  <div className="container-fluid header-width">
                    <ProjectCarousel
                      title="Residential Ongoing Projects"
                      to="/ongoing-projects/"
                      list={ongoing.allOngoingJson.edges}
                    />
                    <ProjectCarousel
                      title="Residential Completed Projects"
                      to="/completed-projects/"
                      list={completed.allCompletedJson.edges}
                    />
                  </div>
                </nav>
              </div>
              <div className="header-link resident-link">
                Commercial
                <span className="ml-1">
                  <ChevDown />
                </span>
                <nav className="resident-nav" id="residentNav">
                  <div className="container-fluid header-width">
                    <CustomProjectCarousel
                      title="Commercial Ongoing Projects"
                      to="/commercial-ongoing-projects/"
                      list={promenadeOngoingData}
                    />
                    <CustomProjectCarousel
                      title="Commercial Completed Projects"
                      to="/commercial-completed-projects/"
                      list={completeddata}
                    />
                  </div>
                </nav>
              </div>
              {links.map((x) => (
                <HeaderLink {...x} key={x.title} />
              ))}
            </div>
            <button
              className="btn btn-ham"
              id="menuBtn"
              onClick={() => set(!show)}
            >
              <MenuIcon />
            </button>
          </nav>
        </header>
        <nav
          className={`mobile-nav${show ? ` mobile-nav-open` : ``}`}
          id="mobileNav"
        >
          <HeaderLink
            isMobile
            title="Residential Ongoing Projects"
            to="/ongoing-projects/"
          />
          <HeaderLink
            isMobile
            title="Residential Completed Projects"
            to="/completed-projects/"
          />
          <HeaderLink
            isMobile
            title="Commercial Ongoing Projects"
            to="/commercial-ongoing-projects/"
          />
          <HeaderLink
            isMobile
            title="Commercial Completed Projects"
            to="/commercial-completed-projects/"
          />
          {links.map((x) => (
            <HeaderLink {...x} isMobile key={x.title} />
          ))}
        </nav>
      </div>
    </>
  );
}

export default Header;
