import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

function PromenadeOngoingData() {
  const usePromenadeData = () =>
    useStaticQuery(graphql`
      query {
        commercialOngoingJson(slug: { eq: "promenade" }) {
          id
          is_active
          is_custom
          ribbon_text
          title
          slug
          rera
          tagline
          banner {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          brouchureUrl
          callUsNumber
          video {
            type
            link
          }
          short_desc
          footer_desc
          sections {
            gallery {
              title
              images {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            project_updates {
              title
              images {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              videos {
                type
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                link
              }
            }
          }
        }
      }
    `);

  const data = usePromenadeData();
  const projectData = data.commercialOngoingJson;
  return [projectData];
}

export default PromenadeOngoingData;
