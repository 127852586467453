import { useStaticQuery, graphql } from "gatsby";

const useCompletedList = () => {
  return useStaticQuery(graphql`
    query {
      allCompletedJson(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            title
            slug
            banner {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            short_desc
            footer_desc
          }
        }
      }
    }
  `);
};

export default useCompletedList;
